
import { IItemUpdate, IRecommendationUpdate, ITargetLabel, IUserLabels } from "@/interfaces";
import {
  dispatchAddItemLabels,
  dispatchGetCorrectionRecommendation,
  dispatchGetLastItem,
  dispatchGetMetrics,
  dispatchGetPredictions,
  dispatchGetRecommendation,
  dispatchGetSearchRecommendation,
  dispatchGetValidationRecommendation,
} from "@/store/model/actions";
import {
  readAccuracy,
  readFirstLabelContainer,
  readItems,
  readLabel,
  readLabels,
  readMetrics,
  readModel,
  readModels,
  readPrediction,
  readPredictionsByIds,
  readRecommendation,
  readRecommendationType,
  readTarget,
  readTotalPredictions,
} from "@/store/model/getters";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import { api } from "@/api";

import { ILabelContainerUpdate } from "@/interfaces";
import { readHasAdminAccess, readToken, readUserProfile } from "@/store/main/getters";
import { dispatchUpdateLabelContainer } from "@/store/model/actions";
import { dispatchGetDatasets } from "@/store/dataset/actions";

import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import ItemPreview from "@/components/ItemPreview.vue";
import ItemPreviewMultiLabel from "@/components/ItemPreviewMultiLabel.vue";
import ItemPreviewContext from "@/components/ItemPreviewContext.vue";
import LabelBar from "@/components/LabelBar.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import Particle from "@/components/Particle.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import TextEditor from "@/components/TextEditor.vue";

@Component({
  components: {
    ItemPreviewMultiLabel,
    ItemPreview,
    ItemPreviewContext,
    ModelProgress,
    LabelBar,
    Particle,
    ConfusionMatrix,
    ToolbarButton,
    RoundProgress,
    TextEditor,
  },
})
export default class LabelingView extends Vue {
  public id: number = 1;
  public error: string = "";
  public showMetric: boolean = false;
  public metricThreshold: number = 0.0;
  public dialogLabel: boolean = false;
  public dialogLabelContainer: boolean = false;
  public labelName: string = "";
  public labelExamples: string[] = [];
  public showLabelWarning: boolean = false;
  public labelContainerName: string = "";
  public activeLabelContainerId: number = -1;
  public activeLabelId: number = -1;
  public activeItemId: string = "";
  public totalClicks: number = 0;
  public startTime: any = null;
  public activeLabelExample: string = "";
  public preview: boolean = false;
  public startLabel: boolean = true;
  public loading: boolean = false;
  public redoing: boolean = false;
  public showplease: boolean = true;
  public loadingItem: boolean = false;
  public lastItem: any = { plain_text: "" };
  // MULTILABEL
  public multiLabelList: number[] = [0];
  public now: number = new Date().getTime();
  public dialogSearch: boolean = false;
  public searchPhrase: string = "";
  public timerId: any = null;
  public languageList: any = [];
  public chosenLanguage: string = "";
  public showContext: boolean = false;

  public searchFocus: boolean = true;
  public active: number = 0;
  public showSearchPhrase: string = "";

  public showAnimation: boolean = false;
  public animationStartTime: any = 0;
  public animationNow: number = 0;
  public intervalId: any = null;
  public targetItems: IItemUpdate[] = [];
  public modelChoiceDialog: boolean = false;
  public nextPageLoading: boolean = false;

  public nisseQuotes: string[] = [
    "I'm searching for some good examples!",
    "holy guacamole, I'm finding interesting things!",
    "Just give me a little more time",
    "Dont mind me, I'm browsing",
    "My magnifier is awesome",
    "There is a lot to read through!",
  ];
  public nisseQuote: string = "Hello there!";

  public nisseTrainingQuotes: string[] = [
    "I just Elfed up!",
    "Im getting stronger!",
    "Wow, I felt that! More please",
    "Thanks for the data. I love data!",
  ];
  public nisseTrainingQuote: string = "I'm getting stronger";
  public timedClose: boolean = true;
  public closeMenu: boolean = false;

  public color: string = "green";
  public colors: string[] = [
    "red",
    "purple",
    "pink",
    "deep-purple",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
    "black",
    "white",
  ];

  @Ref("xpbar") public readonly xp!: Element;

  @Watch("loadingItem")
  public onPropertyChanged(value: boolean, oldValue: boolean) {
    this.nisseQuote = this.nisseQuotes[Math.floor(Math.random() * this.nisseQuotes.length)];
    this.nisseTrainingQuote =
      this.nisseTrainingQuotes[Math.floor(Math.random() * this.nisseQuotes.length)];

    if (value === true) {
      this.animationStartTime = new Date().getTime();
      this.intervalId = setInterval(() => (this.animationNow = new Date().getTime()), 1000);
    }
    if (oldValue === true) {
      this.animationNow = 0;
      clearInterval(this.intervalId);
    }
  }

  get allPicked() {
    let ret = false;
    this.targetItems.forEach((eachItem) => {
      if (eachItem.user_labels[0].label === null) {
        ret = true;
      }
    });
    return ret;
  }

  get xper() {
    return "xp";
  }

  get maxPredictedSingelLabel() {
    // function that returns the label with highest probability for a single label model (single recommendation)
    // get index with max prediction, take label id, return label obj!
    const index = [...this.items[0].predictions.keys()].reduce((a, b) =>
      this.items[0].predictions[a].prediction > this.items[0].predictions[b].prediction ? a : b,
    );
    return this.labelById(this.items[0].predictions[index].label)[0];
  }

  public async getCorrections() {
    this.delayCloseAlert();
    this.loadingItem = true;
    this.error = "";
    this.targetItems = [];
    this.redoing = false;
    this.loading = true;
    await dispatchGetCorrectionRecommendation(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      languageFilter: this.chosenLanguage,
    })
      .then(() => {
        this.loadingItem = false;
        this.loading = false;

        this.delayCloseAlert();
        this.error = "";
        this.targetItems = [];
        this.redoing = false;

        const takenIds: string[] = [];
        this.targetItems.forEach((i) => {
          takenIds.push(i.id);
        });
        const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));
        if (this.model!.label_containers[0].type === "multi") {
          filteredItems.forEach((i) => {
            this.fillTargetsForMultiLabelBulk(i.id, i.store_id);
          });
        } else {
          if (this.recommendationType !== "single" && this.firstLabelContainer) {
            filteredItems.forEach((i) => {
              this.createItemUpdate(
                this.getOrderedLabels(this.firstLabelContainer!.labels, i)[0].id,
                i.id,
                i.store_id,
              );
            });
          }
        }
        // end
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.error = error.response;
        this.loading = false;
      });
  }

  public async getModelLanguages() {
    await api
      .getModelLanguages(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.model!.id,
      )
      .then((r) => {
        this.languageList = r.data;
      })
      .catch((error) => {
        console.log("UploadError", error.response);
      });
  }
  public startLabelling() {
    this.startLabel = true;
    this.getRecommendationAndCreateBulk();
  }

  public activateModelDialog() {
    this.modelChoiceDialog = true;
  }

  public setModelToMulti() {
    const labelContainer: ILabelContainerUpdate = {
      name: "null",
      type: "multi",
    };
    dispatchUpdateLabelContainer(this.$store, {
      modelId: this.model!.id,
      labelContainerId: this.firstLabelContainer!.id,
      labelContainer,
    }).then((r) => {
      this.modelChoiceDialog = false;
      this.getRecommendationAndCreateBulk();
    });
  }

  // MULTILABEL

  public fillTargetsForMultiLabelBulk(itemId, storeId) {
    // This function is run when we create bulks etc. to fill the targets with [0].
    // If we want pre-filled choices, populate label with item.predictions instead.
    const label: ITargetLabel = {
      label_container: this.firstLabelContainer!.id,
      label: 0,
    };

    const item: IItemUpdate = {
      id: itemId,
      store_id: storeId,
      user_labels: [label],
    };

    this.targetItems.push(item);

    const takenIds: string[] = [];
    takenIds.push(itemId);
  }

  public createItemUpdateMultiLabel(labelId, itemId, storeId) {
    // Function for updating the targetItems when clicking buttons
    const targetItem = this.targetItems!.filter((targetItem) => targetItem.id === itemId);
    console.log(targetItem);
    // Take out the label list
    let userLabelList = targetItem[0].user_labels;

    // Make typescript happy
    if (!Array.isArray(userLabelList)) {
      userLabelList = [userLabelList];
    }

    if (!this.loading && this.firstLabelContainer) {
      if (labelId === -2) {
        const label: ITargetLabel = {
          label_container: this.firstLabelContainer!.id,
          label: -2,
        };
        userLabelList = [label];
        this.activeItemId = itemId;
      } else {
        if (userLabelList.some((entry) => entry.label === 0)) {
          // Remove the 0 label if it exists
          userLabelList = userLabelList.filter((entry) => entry.label !== 0);
        }
        if (userLabelList.some((entry) => entry.label === -2)) {
          // Remove the -2 label if it exists
          userLabelList = userLabelList.filter((entry) => entry.label !== 0);
        }
        if (userLabelList.some((entry) => entry.label === labelId)) {
          // Remove the label if it exists
          userLabelList = userLabelList.filter((entry) => entry.label !== labelId);
        } else {
          // Add the label if it doesn't exist
          const label: ITargetLabel = {
            label_container: this.firstLabelContainer!.id,
            label: labelId,
          };
          userLabelList.push(label);
        }
        if (userLabelList.length < 1) {
          const label: ITargetLabel = {
            label_container: this.firstLabelContainer!.id,
            label: 0,
          };
          userLabelList.push(label);
        }
      }
    }

    const item: IItemUpdate = {
      id: itemId,
      store_id: storeId,
      user_labels: userLabelList,
    };

    // const takenIds: string[] = [];
    // this.targetItems.forEach((i) => {
    //   takenIds.push(i.id);
    // });

    const filteredItems = this.targetItems.filter((filteringItem) => itemId !== filteringItem.id);
    this.targetItems = filteredItems;
    this.targetItems.push(item);
    this.activeItemId = "";

    console.log(this.targetItems);
  }

  public async saveMultiLabels() {
    this.lastItem = { ...this.items[0] };
    const itemCount = this.items.length + 1 - 1;

    const update: IRecommendationUpdate = {
      recommendation_type: readRecommendationType(this.$store),
      recommendation: readRecommendation(this.$store),
      target: readTarget(this.$store),
      items: this.targetItems,
    };
    this.loadingItem = true;
    this.loading = true;
    this.targetItems = [];
    this.pop();
    await dispatchAddItemLabels(this.$store, {
      modelId: this.model!.id,
      labels: update,
    }).then(
      (r) => {
        this.loadingItem = false;
        if (this.startTime === null) {
          this.startTime = new Date().getTime();
          setInterval(() => (this.now = new Date().getTime()), 1000);
        } else {
          this.totalClicks = this.totalClicks + itemCount;
        }
        this.redoing = false;
        if (this.recommendation === "plain_text_search") {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.getSearchRecommendationAndCreateBulk();
          }, 1000);
        } else {
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.getRecommendationAndCreateBulk();
        }
        dispatchGetPredictions(this.$store, {
          modelId: parseInt(this.$router.currentRoute.params.id, 10),
          threshold: this.metricThreshold,
        });
        this.loading = false;
      },
      (reason) => {
        console.log(reason);
      },
    );
  }

  // MULTILABEL

  public saveItemUpdate(labelId, itemId, storeId) {
    if (labelId === 0 && itemId === 0) {
      this.modelChoiceDialog = true;
      return;
    }

    if (!this.loading && this.firstLabelContainer) {
      const label: ITargetLabel = {
        label_container: this.firstLabelContainer!.id,
        label: labelId,
      };

      const item: IItemUpdate = {
        id: itemId,
        store_id: storeId,
        user_labels: [label],
      };
      this.targetItems.push(item);
      this.saveLabels();
    }
  }
  public delayCloseAlert() {
    this.timedClose = true;
  }
  public createItemUpdate(labelId, itemId, storeId) {
    if (labelId === 0 && itemId === 0) {
      this.modelChoiceDialog = true;
      return;
    }

    const label: ITargetLabel = {
      label_container: this.firstLabelContainer!.id,
      label: labelId,
    };

    const item: IItemUpdate = {
      id: itemId,
      store_id: storeId,
      user_labels: [label],
    };

    const takenIds: string[] = [];
    this.targetItems.forEach((i) => {
      takenIds.push(i.id);
    });

    const filteredItems = this.targetItems.filter((filteringItem) => itemId !== filteringItem.id);
    this.targetItems = filteredItems;
    this.targetItems.push(item);
    this.activeItemId = "";
  }

  get takenIds() {
    const takenIds: string[] = [];
    this.targetItems.forEach((i) => {
      takenIds.push(i.id);
    });
    return takenIds;
  }

  public saveAll() {
    const takenIds: string[] = [];
    this.targetItems.forEach((i) => {
      takenIds.push(i.id);
    });
    const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));

    filteredItems.forEach((i) => {
      this.createItemUpdate(i.predictions[0].label, i.id, i.store_id);
    });
    this.saveLabels();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  public async getMetrics() {
    let labelId: number | null;
    if (this.model!.label_containers[0].type !== "multi") {
      labelId = null;
    } else {
      labelId = this.labels[0].id;
    }

    const trainMetrics: boolean = true;

    dispatchGetMetrics(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      threshold: this.metricThreshold,
      labelId,
      train: trainMetrics,
    });
    dispatchGetPredictions(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      threshold: this.metricThreshold,
    });
  }

  get nextTraining() {
    if (this.model!.total_labeled < 20) {
      return (this.model!.total_labeled / 20) * 100;
    }
    return ((this.model!.total_labeled % 20) / 20) * 100;
  }
  get nextTrainingFeedback() {
    if (this.recommendation === "validation") {
      return "Testing Labelf";
    }
    if (this.model) {
      if (this.model!.label_containers[0].labels.length < 2) {
        return `You must add at least two labels before Labelf can start learning!`;
      } else if (this.model!.total_labeled < 5) {
        return `Lets start by giving Labelf ${20 - this.model!.total_labeled} samples`;
      } else if (this.model!.total_labeled < 20) {
        return `Only ${20 - this.model!.total_labeled} more to go!`;
      }
      return `In ${20 - (this.model!.total_labeled % 20)} samples Labelf will get stronger!`;
    }
    return "";
  }

  /*    ________________________________________________     */
  /*                       GETTERS                           */
  /*    ________________________________________________     */

  get isAdmin() {
    return readHasAdminAccess;
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get highlights() {
    let highlights: any = [];
    if (
      this.model &&
      this.model!.label_containers.length &&
      this.model!.label_containers[0].labels.length
    ) {
      this.model!.label_containers[0].labels.forEach((l) => {
        const words = l.name.split(" ");
        const longWords = words.filter((w) => w.length > 4);

        if (longWords.length > 0) {
          highlights = highlights.concat(longWords);
        }
      });
    }
    return highlights;
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get predictions() {
    return readPrediction(this.$store);
  }

  get targetLabel() {
    // always convert target to number (can also be number[]) e.g. [1] or 1
    if (Array.isArray(this.target!.label)) {
      return readLabel(this.$store)(+this.$router.currentRoute.params.id, this.target!.label[0]);
    } else {
      return readLabel(this.$store)(+this.$router.currentRoute.params.id, this.target!.label);
    }
  }

  get predictionsByIds() {
    const predcounts = readPredictionsByIds(this.$store);

    // check if empty (js things)
    if (Object.keys(predcounts).length !== 0) {
      return predcounts;
    }

    // create empty if not
    let res: any = {};
    this.labels!.forEach((l) => {
      res = { ...res, ...{ [l.id]: 0 } };
    });
    return res;
  }

  get totalPredictions() {
    return readTotalPredictions(this.$store);
  }

  get accuracy() {
    return readAccuracy(this.$store);
  }

  get token() {
    return readToken(this.$store);
  }

  get metrics() {
    return readMetrics(this.$store);
  }

  get models() {
    return readModels(this.$store);
  }

  get recommendation() {
    return readRecommendation(this.$store);
  }
  get recommendationType() {
    return readRecommendationType(this.$store);
  }
  get target() {
    return readTarget(this.$store);
  }

  get item() {
    return this.items[0];
  }
  get items() {
    return readItems(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get valueDeterminate() {
    return 20;
  }

  public changeColor(color) {}

  public createLabel(id) {
    this.dialogLabel = true;
    this.activeLabelContainerId = id;
  }

  public createLabelContainer() {
    this.dialogLabelContainer = true;
  }

  public formatNumber(num) {
    if (num !== undefined) {
      return num
        .toFixed(0)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    return "0";
  }

  public updateLabels(containerId, labelId) {
    if (!this.loading) {
      const label: IUserLabels = {
        label_container: containerId,
        label: labelId,
      };
      this.saveLabels();
    }
  }
  public get clicksPerSecond() {
    const mSecondsPassed = this.now - this.startTime;
    return (this.totalClicks / mSecondsPassed) * (1000 * 60);
  }

  public async saveLabels() {
    this.lastItem = { ...this.items[0] };

    const itemCount = this.items.length + 1 - 1;

    const update: IRecommendationUpdate = {
      recommendation_type: readRecommendationType(this.$store),
      recommendation: readRecommendation(this.$store),
      target: readTarget(this.$store),
      items: this.targetItems,
    };
    this.loading = true;
    this.targetItems = [];
    this.pop();
    await dispatchAddItemLabels(this.$store, {
      modelId: this.model!.id,
      labels: update,
    }).then(
      (r) => {
        if (this.startTime === null) {
          this.startTime = new Date().getTime();
          setInterval(() => (this.now = new Date().getTime()), 1000);
        } else {
          this.totalClicks = this.totalClicks + itemCount;
        }
        this.redoing = false;
        if (this.recommendation === "plain_text_search") {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.getSearchRecommendationAndCreateBulk();
          }, 1000);
        } else {
          this.getRecommendationAndCreateBulk();

          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        dispatchGetPredictions(this.$store, {
          modelId: parseInt(this.$router.currentRoute.params.id, 10),
          threshold: this.metricThreshold,
        });
        this.loading = false;
      },
      (reason) => {
        console.log(reason);
        this.loading = false;
      },
    );
  }

  public async getNewItem() {
    this.redoing = false;
    this.targetItems = [];
    // get id
    let lastId: string | null = null;
    try {
      lastId = this.items[0].id;
    } catch {
      console.log("no items");
    }
    this.getRecommendationAndCreateBulk(lastId);
  }

  public getSearch() {
    // cancel pending call
    clearTimeout(this.timerId);
    this.showSearchPhrase = this.searchPhrase;
    // delay new call 500ms
    if (this.searchPhrase !== "") {
      this.timerId = setTimeout(() => {
        this.getSearchRecommendationAndCreateBulk();
      }, 500);
    } else {
      this.getRecommendationAndCreateBulk();
    }
  }

  public async getSearchRecommendationAndCreateBulk() {
    this.loadingItem = true;
    this.targetItems = [];
    this.error = "";
    // this.redoing = true;
    await dispatchGetSearchRecommendation(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      plainText: this.searchPhrase,
    })
      .then(() => {
        this.loadingItem = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
        const takenIds: string[] = [];
        this.targetItems.forEach((i) => {
          takenIds.push(i.id);
        });
        const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));
        if (this.model!.label_containers[0].type === "multi") {
          filteredItems.forEach((i) => {
            this.fillTargetsForMultiLabelBulk(i.id, i.store_id);
          });
        } else {
          filteredItems.forEach((i) => {
            this.createItemUpdate(null, i.id, i.store_id);
          });
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.error = error.response;
      });
  }

  public async getValidationRecommendationAndCreateBulk() {
    this.loadingItem = true;
    this.targetItems = [];
    this.error = "";
    // this.redoing = true;
    await dispatchGetValidationRecommendation(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
    })
      .then(() => {
        this.loadingItem = false;
        const takenIds: string[] = [];
        this.targetItems.forEach((i) => {
          takenIds.push(i.id);
        });
        const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));
        if (this.model!.label_containers[0].type === "multi") {
          filteredItems.forEach((i) => {
            this.fillTargetsForMultiLabelBulk(i.id, i.store_id);
          });
        } else {
          if (this.recommendationType !== "single" && this.firstLabelContainer) {
            filteredItems.forEach((i) => {
              this.createItemUpdate(null, i.id, i.store_id);
            });
          }
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.error = error.response;
      });
  }

  public async getRecommendationAndCreateBulk(lastId: string | null = null) {
    this.delayCloseAlert();
    this.loadingItem = true;
    this.error = "";
    this.targetItems = [];
    this.redoing = false;
    await dispatchGetRecommendation(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
      languageFilter: this.chosenLanguage,
      lastId,
    })
      .then(() => {
        this.loadingItem = false;
        const takenIds: string[] = [];
        this.targetItems.forEach((i) => {
          takenIds.push(i.id);
        });
        const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));
        if (this.model!.label_containers[0].type === "multi") {
          filteredItems.forEach((i) => {
            this.fillTargetsForMultiLabelBulk(i.id, i.store_id);
          });
        } else {
          if (this.recommendationType !== "single" && this.firstLabelContainer) {
            filteredItems.forEach((i) => {
              if (this.recommendation === "validation") {
                this.createItemUpdate(null, i.id, i.store_id);
              } else {
                console.log(this.getOrderedLabels(this.firstLabelContainer!.labels, i)[0].id);
                this.createItemUpdate(
                  this.getOrderedLabels(this.firstLabelContainer!.labels, i)[0].id,
                  i.id,
                  i.store_id,
                );
              }
            });
          }
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.error = error.response;
      });
  }

  public getPredictionOrder(id, item) {
    const filteredItems = item.predictions.filter((prediction) => prediction.label === id);

    if (filteredItems.length > 0) {
      return filteredItems[0].prediction;
    }
    return 0;
  }

  public getOrderedLabels(labels, item) {
    const labelsToSort = labels.slice();

    labelsToSort.sort((a, b) => {
      return this.getPredictionOrder(b.id, item) - this.getPredictionOrder(a.id, item);
    });
    return labelsToSort;
  }

  public async getLastItem() {
    this.error = "";
    this.loadingItem = true;
    this.targetItems = [];
    this.redoing = true;
    this.lastItem = { plain_text: "" };
    await dispatchGetLastItem(this.$store, {
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
    })
      .then(() => {
        this.loadingItem = false;
        const takenIds: string[] = [];
        this.targetItems.forEach((i) => {
          takenIds.push(i.id);
        });
        const filteredItems = this.items.filter((item) => !takenIds.includes(item.id));
        if (this.model!.label_containers[0].type === "multi") {
          filteredItems.forEach((i) => {
            this.fillTargetsForMultiLabelBulk(i.id, i.store_id);
          });
        } else {
          if (this.recommendationType !== "single" && this.firstLabelContainer) {
            filteredItems.forEach((i) => {
              if (this.recommendation === "validation") {
                this.createItemUpdate(null, i.id, i.store_id);
              } else {
                this.createItemUpdate(
                  this.getOrderedLabels(this.firstLabelContainer!.labels, i)[0].id,
                  i.id,
                  i.store_id,
                );
              }
            });
          }
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.error = error.response;
      });
  }

  public labelById(labelId) {
    return this.labels.filter((label) => label.id === labelId);
  }

  public mounted() {
    setTimeout(() => {
      this.getModelLanguages();
      this.getMetrics();
    }, 500);
    dispatchGetDatasets(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
  }
  public async created() {
    this.getRecommendationAndCreateBulk();
  }
  public pop() {
    // Loop to generate 30 particles at once
    for (let i = 0; i < 30; i++) {
      // We pass the mouse coordinates to the createParticle() function
      this.createParticle(window.innerWidth * (this.nextTraining / 100), 150, 200);
    }
  }
  public createParticle(x, y, width) {
    const particle = document.createElement("particle");
    document.body.appendChild(particle);
    // Calculate a random size from 5px to 25px
    const size = Math.floor(Math.random() * 10 + 5);
    particle.style.width = `${size}px`;
    particle.style.height = `${size}px`;
    // Generate a random color in a blue/purple palette
    particle.style.background = `hsl(${Math.random() * 100 + 60}, 90%, 60%)`;

    // Generate a random x & y destination within a distance of 75px from the mouse
    const destinationX = x + (Math.random() - 0.5) * 2 * 75;
    const destinationY = y + (Math.random() - 0.5) * 2 * 75;

    // Store the animation in a variable as we will need it later
    const animation = particle.animate(
      [
        {
          // Set the origin position of the particle
          // We offset the particle with half its size to center it around the mouse
          transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
          opacity: 1,
        },
        {
          // We define the final coordinates as the second keyframe
          transform: `translate(${destinationX}px, ${destinationY}px)`,
          opacity: 0,
        },
      ],
      {
        // Set a random duration from 500 to 1500ms
        duration: Math.random() * 750 + 1000,
        easing: "cubic-bezier(0, .9, .1, 1)",
        // Delay every particle with a random value of 200ms
        delay: Math.random() * 200,
      },
    );

    // When the animation is complete, remove the element from the DOM

    animation.onfinish = () => {
      particle.remove();
    };
  }
}
