
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IDataset } from "@/interfaces";
import { readDatasets, readDataset } from "@/store/dataset/getters";
import { dispatchGetDatasets } from "@/store/dataset/actions";

import {
  readUserProfile,
  readHasAdminAccess,
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
} from "@/store/main/getters";

import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";

@Component({
  components: {
    ToolbarButton,
    RoundProgress,
  },
})
export default class DatasetView extends Vue {
  public id: number = 1;

  public preview: boolean = false;
  public loading: boolean = false;
  public redoing: boolean = false;
  public drawer: boolean = true;

  get offsetMenu() {
    if (!this.showDrawer) {
      return 0;
    } else if (this.miniDrawer) {
      return "80";
    } else if (!this.miniDrawer) {
      return "300";
    }
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  /*    ________________________________________________     */
  /*                       GETTERS                           */
  /*    ________________________________________________     */

  get isAdmin() {
    return readHasAdminAccess;
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get valueDeterminate() {
    return 20;
  }
}
