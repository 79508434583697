
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";

import { readGenerativeModels, readGenerativeModel } from "@/store/generative_model/getters";

import {
  readUserProfile,
  readHasAdminAccess,
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
} from "@/store/main/getters";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
    ToolbarButton,
    RoundProgress,
  },
})
export default class ModelView extends Vue {
  public id: number = 1;
  public showMetric: boolean = false;
  public metricThreshold: number = 0.95;
  public dialogLabel: boolean = false;
  public dialogLabelContainer: boolean = false;
  public labelName: string = "";
  public labelExamples: string[] = [];
  public labelContainerName: string = "";
  public activeLabelContainerId: number = -1;
  public activeLabelId: number = -1;
  public activeItemId: string = "";
  public activeLabelExample: string = "";
  public preview: boolean = false;
  public loading: boolean = false;
  public redoing: boolean = false;
  public drawer: boolean = true;

  public color: string = "green";
  public colors: string[] = [
    "red",
    "purple",
    "pink",
    "deep-purple",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
    "black",
    "white",
  ];

  get offsetMenu() {
    if (!this.showDrawer) {
      return 0;
    } else if (this.miniDrawer) {
      return "80";
    } else if (!this.miniDrawer) {
      return "300";
    }
  }

  get statusCheck() {
    // returns true if status indicates training/uploading etc

    return true;
  }

  public capitalizeFirstLetter(inputString: string): string {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  /*    ________________________________________________     */
  /*                       GETTERS                           */
  /*    ________________________________________________     */

  get isAdmin() {
    return readHasAdminAccess;
  }

  get model() {
    return readGenerativeModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get models() {
    return readGenerativeModels(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get valueDeterminate() {
    return 20;
  }

  public createLabel(id) {
    this.dialogLabel = true;
    this.activeLabelContainerId = id;
  }

  public createLabelContainer() {
    this.dialogLabelContainer = true;
  }
}
