
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IWorkspaceCreate } from "@/interfaces";

import {
  readUserProfile,
  readHasAdminAccess,
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readWorkspace,
  readToken,
} from "@/store/main/getters";

import { dispatchGetWorkspace, dispatchGetUserProfile } from "@/store/main/actions";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import { api } from "@/api";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
    ToolbarButton,
    RoundProgress,
  },
})
export default class WorkspaceView extends Vue {
  public editWorkspaceNameDialog = false;
  public loading = false;
  public newWorkspaceName: string = "";

  get token() {
    return readToken(this.$store);
  }

  public triggerEditWorkspace() {
    // starts dialog and "exports" the user that we want to edit.
    this.editWorkspaceNameDialog = true;
  }

  public async updateWorkspace() {
    this.loading = true;
    const newWorkspaceObj: IWorkspaceCreate = {
      name: this.newWorkspaceName,
    };
    await api
      .updateWorkspace(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        newWorkspaceObj,
      )
      .then((r) => {
        this.editWorkspaceNameDialog = false;
        this.loading = false;
      })
      .catch((error) => {
        console.log("Error", error.response);
        this.loading = false;
      });
    await dispatchGetWorkspace(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    await dispatchGetUserProfile(this.$store);
  }

  public async created() {
    await dispatchGetWorkspace(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    this.newWorkspaceName = this.workspace!.name;
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }
}
